import React, { useRef, useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import SmileyFace from "./SmileyFace";
import "./style.scss";

export default function Lab() {
  const videoRef = useRef(null); // Reference to the video element
  const canvasRef = useRef(null); // Reference to the canvas for capturing frames
  const streamRef = useRef(null); // Reference to store the media stream
  const [isProcessing, setIsProcessing] = useState(false); // State to control processing
  const [detectedItems, setDetectedItems] = useState([]); // State to store detected items
  const handleStopCamera = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks(); // Get all tracks from the stream
      tracks.forEach((track) => track.stop()); // Stop each track
      streamRef.current = null; // Clear the stream reference

      if (videoRef.current) {
        videoRef.current.srcObject = null; // Clear the video source if still available
      }
    }
  };

  useEffect(() => {
    const constraints = { video: true }; // Video constraints for getUserMedia

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        streamRef.current = stream; // Store the stream in the ref
        if (videoRef.current) {
          videoRef.current.srcObject = stream; // Assign the stream to the video element
        }
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });

    // Cleanup function to stop the camera when the component unmounts
    return () => {
      handleStopCamera();
    };
  }, []);

  const captureFrame = useCallback(() => {
    if (!canvasRef.current || !videoRef.current) return;
    console.log("jjjj");
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext("2d");
  
    // Set the canvas size to the video dimensions
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
  
    // Draw the current video frame on the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
    // Convert the canvas image to base64 format
    // const dataUrl = canvas.toDataURL("image/jpeg"); // Convert the canvas to a JPEG image
  
    // Remove the data URL prefix to send only the base64 string
    const dataUrl = canvas.toDataURL('image/jpeg'); // or 'image/png'
  
    sendFrameToAPI(dataUrl);
  }, [canvasRef, videoRef]);

  const sendFrameToAPI = async (base64Image) => {
    try {
      const response = await fetch("http://localhost:4000/stream", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ image: base64Image }),
      });

      if (response.ok) {
        const predictions = await response.json();
        setDetectedItems(predictions);
        console.log("Frame sent successfully!", predictions)
      } else {
        console.error("Error sending frame:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending frame to API:", error);
    }
  };

  const handleProcessVideo = () => {
    setIsProcessing(!isProcessing); // Toggle processing state
  };

  // Periodically capture frames when processing is active
  useEffect(() => {
    let intervalId;
    if (isProcessing) {
      intervalId = setInterval(captureFrame, 1000); // Capture a frame every second
    }

    return () => {
      clearInterval(intervalId); // Clear the interval when processing stops or component unmounts
    };
  }, [captureFrame, isProcessing]);

  return (
    <>
      <div className="stylish-title">LAB</div>
      <div className="container">
        <div className="video-button-container camera">
          <div className="circle-video">
            <video ref={videoRef} autoPlay playsInline className="circle-video" />
          </div>
          <button className="flat-button" onClick={handleProcessVideo}>
            <FontAwesomeIcon icon="spinner" spin={isProcessing} />
            <span style={{ paddingLeft: "5px" }}>
              {isProcessing ? "Stop Processing" : "Process the Video"}
            </span>
          </button>
        </div>
        <div>
        <div className="detected-items-title">Detected Items:</div>
        {detectedItems.length === 0 && (<div className="not-detected-item">
            Nothing detected yet!
          </div>)}
        {detectedItems.map((prediction, index) => (
          <div key={index} className="detected-items">
            {prediction.class}
          </div>
        ))}
      </div>

        
        {/* <SmileyFace /> */}
        <canvas ref={canvasRef} style={{ display: "none" }} /> 
      </div>
    </>
  );
}


