import React, {useState} from 'react';

const BrowserAI = () => {
    const [response, setResponse] = useState(null);
    const [question, setQuestion] = useState(null);


    const callAI = async () => {
        try {
            // Ensure window.ai and window.ai.assistant are available
            if (!window.ai || !window.ai.assistant) {
                console.error('window.ai or window.ai.assistant is not available.');
                return; // Prevent further execution if window.ai or window.ai.assistant is not available
            }
    
            // Optionally, check capabilities
            const capabilities = await window.ai.assistant.capabilities();
            console.log('Capabilities:', capabilities);
    
            // Attempt to get available models using different methods
            const models = ['gpt-3.5-turbo']; // Replace with a model you have access to
    
    
            // Create a session with the specified model
            const session = await window.ai.assistant.create({ modelId: models[0] });
            setResponse("Loading...");
            // Execute the prompt
            const res = await session.prompt(question);
    
            setResponse(res);
            console.log('Response:', res);
    
            // Destroy the session
            await session.destroy();
    
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };
    
    
    return (
        <div>
            <h1>Browser AI Component</h1>
            <p>Ask questions</p>
            <input 
                type="text" 
                placeholder="Ask something..." 
                onChange={(e) => setQuestion(e.target.value)} 
                style={{ padding: '10px', fontSize: '16px', width: '300px', marginBottom: '10px', borderRadius: '5px', borderColor: '#ea5455' }}
            /><br></br>
            <button onClick={callAI}>Ask AI</button>
            {response && <p>{response}</p>}
        </div>
    );
};

export default BrowserAI;