import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { Link, useLocation } from "react-router-dom";
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import "./style.scss";

const routeConfig = {
  home: null,
  about: null,
  blogs: null,
  labs: null,
};
export default function Header() {
  let { state } = useLocation();
  console.log(state);

  const activeMenuItem = () =>
    Object.entries(routeConfig).map(([key]) => {
      if (state && key === state.currentLocation) {
        routeConfig[key] = "visited";
      } else {
        routeConfig[key] = null;
      }
      return routeConfig;
    });

  activeMenuItem();
  return (
    <div className="content">
      <div className="header">
        <div className="title">Semicolon</div>
        <div className="sub-title">Just think...</div>
      </div>
      <div className="sub-header">
      <Link
              to="/"
              className="link-color"
              state={{ currentLocation: "home" }}
            >
        <div className="menu-item">
          <span className={`menu-text margin-right-5px ${routeConfig.home}`}>
              <span className="icon-right-padding">
                <FontAwesomeIcon icon="home" fixedWidth />
              </span>
              Home
          </span>
        </div>
        </Link>
        <Link
              to="about"
              className="link-color"
              state={{ currentLocation: "about" }}
            >
        <div className="menu-item">
          <span className={`menu-text margin-right-5px ${routeConfig.about}`}>
   
              <span className="icon-right-padding">
                <FontAwesomeIcon icon="id-card" />
              </span>
              About me
          </span>
        </div>
        </Link>
        <Link
              to="/cms/publish/post"
              className="link-color"
              state={{ currentLocation: "blogs" }}
            >
        <div className="menu-item">
          <span className={`menu-text margin-right-5px ${routeConfig.blogs}`}>

              <span className="icon-right-padding">
              <FontAwesomeIcon icon={faBookmark} />
              </span>
              My Blogs
          </span>
        </div>
        </Link>
        <Link
              to="/labs"
              className="link-color"
              state={{ currentLocation: "labs" }}
            >
        <div className="menu-item">
          <span className={`menu-text margin-right-5px ${routeConfig.labs}`}>

              <span className="icon-right-padding">
              <FontAwesomeIcon icon={faHourglassHalf} beatFade/>
              </span>
              Labs
          </span>
        </div>
        </Link>
      

      </div>
    </div>
  );
}
